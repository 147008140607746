import React, { useEffect } from "react";
import { Modal, Form, Input, Col, Row, message } from "antd";
import { API_METHODS } from "../../../constants";
import useApi from "../../../hooks/useApi";
function AddWard(props) {
  const [form] = Form.useForm();
  const { visible, close, selectedRecord } = props;
  const [response, request] = useApi("/config/ward", null, API_METHODS.POST);
  const [editResponse, editRequest] = useApi(
    `/config/ward/${selectedRecord && selectedRecord.id}`,
    null,
    API_METHODS.PUT
  );

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("Ward added successfully.");
    }
  }, [response]);

  useEffect(() => {
    if (editResponse.data) {
      close(true);
      message.success("Ward updated successfully.");
    }
  }, [editResponse]);

  useEffect(() => {
    if (selectedRecord) {
      form.setFieldsValue(selectedRecord);
    }
  }, [selectedRecord]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (selectedRecord) {
          editRequest(values);
        } else {
          request(values, API_METHODS.POST);
        }
      })
      .catch((e) => {});;
  };

  const handleCancel = () => {
    close();
  };

  return (
    <Modal
      title={selectedRecord ? "Update Ward" : "Add Ward"}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={500}
      okText="Save"
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="name"
              label="Ward Name"
              rules={[
                { required: true, message: "Ward Name is required." },
                {
                  max: 200,
                  message: "Ward Name should be maximum of 200 characters.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AddWard;
