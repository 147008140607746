import React, { useEffect } from "react";
import { Modal, Form, Input, Col, Row, message, Select } from "antd";
import { API_METHODS } from "../../../constants";
import useApi from "../../../hooks/useApi";
import { getClientConfig, isEmpty } from "../../../generalFunctions";

function AddProject(props) {
  const [form] = Form.useForm();
  const { visible, close, selectedRecord } = props;
  const [response, request] = useApi("/config/project", null, API_METHODS.POST);
  const [editResponse, editRequest] = useApi(
    `/config/project/${selectedRecord && selectedRecord.id}`,
    null,
    API_METHODS.PUT
  );
  const { company } = getClientConfig();

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("Project added successfully.");
    }
  }, [response]);

  useEffect(() => {
    if (editResponse.data) {
      close(true);
      message.success("Project updated successfully.");
    }
  }, [editResponse]);

  useEffect(() => {
    if (selectedRecord) {
      form.setFieldsValue(selectedRecord);
    }
  }, [selectedRecord]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (selectedRecord) {
          editRequest(values);
        } else {
          request(values, API_METHODS.POST);
        }
      })
      .catch((e) => {});
  };

  const handleCancel = () => {
    close();
  };

  return (
    <Modal
      title={selectedRecord ? "Update Project" : "Add Project"}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      okText="Save"
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Project Name"
              rules={[
                { required: true, message: "Project Name is required." },
                {
                  max: 500,
                  message: "Project Name should be maximum of 500 characters.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="location"
              label="Location"
              rules={[
                {
                  max: 200,
                  message: "Location should be maximum of 200 characters.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="companyId"
              label="Company"
              rules={[{ required: true, message: "Company is required." }]}
            >
              <Select
                placeholder="Select Company"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {!isEmpty(company) &&
                  company.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.companyName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AddProject;
