import React, { useEffect } from "react";
import { Modal, Form, Input, Col, Row, message } from "antd";
import { API_METHODS } from "../../../constants";
import useApi from "../../../hooks/useApi";

function AddCompany(props) {
  const [form] = Form.useForm();
  const { visible, close, selectedRecord } = props;
  const [response, request] = useApi("/config/company", null, API_METHODS.POST);
  const [editResponse, editRequest] = useApi(
    `/config/company/${selectedRecord && selectedRecord.id}`,
    null,
    API_METHODS.PUT
  );

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("Company added successfully.");
    }
  }, [response]);

  useEffect(() => {
    if (editResponse.data) {
      close(true);
      message.success("Company updated successfully.");
    }
  }, [editResponse]);

  useEffect(() => {
    if (selectedRecord) {
      form.setFieldsValue(selectedRecord);
    }
  }, [selectedRecord]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (selectedRecord) {
          editRequest(values);
        } else {
          request(values, API_METHODS.POST);
        }
      })
      .catch((e) => {});;
  };

  const handleCancel = () => {
    close();
  };

  return (
    <>
      <Modal
        title={selectedRecord ? "Update Company" : "Add Company"}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        okText="Save"
      >
        <Form layout="vertical" form={form}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="companyName"
                label="Company Name"
                rules={[
                  { required: true, message: "Company Name is required." },
                  {
                    max: 100,
                    message:
                      "Company Name should be maximum of 100 characters.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    max: 500,
                    message: "Address should be maximum of 500 characters.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default AddCompany;
