import { Button, Card, Space, Tabs, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../../constants";
import useApi from "../../../hooks/useApi";
import TableComponent from "../../common/table/TableComponent";
import { useNavigate } from "react-router-dom";

function TicketTable() {
  const [activeTab, setActiveTab] = useState("tickets");
  const columns = [
    {
      title: "Beneficiary",
      dataIndex: "beneficiaryDisplayName",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Date Time",
      dataIndex: "raisedOn",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <Tag
          color={text === "closed" ? "#87d068" : "#f50"}
          style={{ fontSize: 14 }}
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          <Space>
            {activeTab === "tickets" && (
              <Button
                size="small"
                color=""
                onClick={() => replyClicked(record)}
              >
                Reply
              </Button>
            )}
            <Button size="small" onClick={() => viewClicked(record, true)}>
              View
            </Button>
          </Space>
        </>
      ),
    },
  ];

  const [responseData, getRequest] = useApi(
    "/admin/ticket",
    null,
    API_METHODS.GET
  );

  const [closedTickets, getClosedTickets] = useApi(
    "/admin/ticket/closed",
    null,
    API_METHODS.GET
  );

  const [tableData, setTableData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const navigate = useNavigate();

  const replyClicked = (record, isViewOnly = false) => {
    navigate("/portal/ticket/detail", {
      state: {
        ticket_id: record.id,
        isViewOnly,
      },
    });
  };
  const viewClicked = (record, isViewOnly = false) => {
    navigate("/portal/ticket/detail", {
      state: {
        ticket_id: record.id,
        isViewOnly,
      },
    });
  };

  useEffect(() => {
    getRequest(null, API_METHODS.GET, `/admin/ticket?pageNumber=${1}`);
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    setTableData(data);
    setTotalRecords(data && data.length > 0 ? data[0].count : 0);
  }, [responseData]);

  useEffect(() => {
    const data = closedTickets.data !== BLANK_VALUE ? closedTickets.data : [];
    setTableData(data);
    setTotalRecords(data && data.length > 0 ? data[0].count : 0);
  }, [closedTickets]);

  const onPaginationChange = (page) => {
    setCurrentPage(page.current);
    if (activeTab === "tickets") {
      getRequest(
        null,
        API_METHODS.GET,
        `/admin/ticket?pageNumber=${page.current}`
      );
    } else if (activeTab === "closed tickets") {
      getClosedTickets(
        null,
        API_METHODS.GET,
        `/admin/ticket/closed?pageNumber=${page.current}`
      );
    }
  };

  const items = [
    {
      key: "tickets",
      label: "Open",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
    {
      key: "closed tickets",
      label: "Closed",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={closedTickets.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
  ];

  const onTabChange = (key) => {
    setActiveTab(key);
    setCurrentPage(1);
    if (key === "tickets") {
      getRequest(null, API_METHODS.GET, `/admin/ticket?pageNumber=${1}`);
    } else {
      getClosedTickets(
        null,
        API_METHODS.GET,
        `/admin/ticket/closed?pageNumber=${1}`
      );
    }
  };

  return (
    <Card title="Tickets">
      <Tabs
        defaultActiveKey="tickets"
        activeKey={activeTab}
        items={items}
        onChange={onTabChange}
      />
    </Card>
  );
}

export default TicketTable;
