import {
  Button,
  Card,
  Col,
  message,
  Popconfirm,
  Row,
  Space,
  Tabs,
  Typography,
  Image,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import useApi from "../../hooks/useApi";
import AddDashboardImages from "./AddDashboardImages";
import { DeleteOutlined } from "@ant-design/icons";
import noImage from "../../assests/images/no-image.png";
import { isEmpty } from "../../generalFunctions";
const { Text } = Typography;

function ViewDashboardImages() {
  const [responseData, getRequest] = useApi(
    "/admin/dashboard-images",
    null,
    API_METHODS.GET
  );
  const [visible, setVisible] = useState(false);
  const [commonImages, setCommonImages] = useState([]);
  const [beneficiaryImages, setBeneficiaryImages] = useState([]);
  const [activeTab, setActiveTab] = useState("common");
  const [deleteResponse, deleteRequest] = useApi();
  const [togglePriorityResponse, togglePriorityRequest] = useApi(
    "/admin/dashboard-images",
    null,
    API_METHODS.PUT
  );

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    getRequest();
  }, [togglePriorityResponse]);

  useEffect(() => {
    if (deleteResponse.data) {
      getRequest();
      message.success("Images deleted successfully.");
    }
  }, [deleteResponse]);

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  const togglePriority = (data, priority) => {
    let body = { priority };
    togglePriorityRequest(
      body,
      API_METHODS.PUT,
      `/admin/dashboard-images/${data.id}`
    );
  };

  const deleteOk = (record) => {
    deleteRequest(
      null,
      API_METHODS.DELETE,
      `/admin/dashboard-images/${record.id}`
    );
  };

  const getDeleteButton = (data) => {
    return (
      <>
        <Popconfirm
          title="Delete Image"
          description={`Are you sure to delete this image?`}
          onConfirm={() => deleteOk(data)}
        >
          <Button size="small" type="primary" danger>
            <DeleteOutlined /> Delete
          </Button>
        </Popconfirm>
      </>
    );
  };

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];

    if (data && data.length) {
      let commonImages = [];
      let beneficiaryImages = [];
      for (let i = 0; i < data.length; i++) {
        if (isEmpty(data[i].beneficiaryIds)) {
          commonImages.push(
            <Col span={6} style={{ marginBottom: 15 }}>
              <Card>
                <Space direction="vertical" size={24} style={{ width: "100%" }}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Image
                        width={"100%"}
                        height={100}
                        src={data[i].imagePath}
                        placeholder={
                          <Image preview={false} src={noImage} width={100} />
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={14}>
                      <strong>Priority:</strong>
                      <Typography.Text>{data[i].priority}</Typography.Text>
                      <Switch
                        checkedChildren="High"
                        unCheckedChildren="Low"
                        onClick={(value) => togglePriority(data[i], value)}
                        checked={data[i].priority}
                      />
                    </Col>
                    <Col span={6} offset={1}>
                      {getDeleteButton(data[i])}
                    </Col>
                  </Row>
                </Space>
              </Card>
            </Col>
          );
        } else {
          beneficiaryImages.push(
            <Col span={6} style={{ marginBottom: 15 }}>
              <Card>
                <Space direction="vertical" size={24} style={{ width: "100%" }}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Image
                        width={"100%"}
                        height={100}
                        src={data[i].imagePath}
                        placeholder={
                          <Image preview={false} src={noImage} width={100} />
                        }
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={14}>
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <Text>
                          Benificiaries:{" "}
                          {<strong>{data[i].beneficiaries.toString()}</strong>}
                        </Text>
                      </Space>
                    </Col>
                    <Col span={3}>{getDeleteButton(data[i])}</Col>
                  </Row>
                </Space>
              </Card>
            </Col>
          );
        }
      }
      setCommonImages(commonImages);
      setBeneficiaryImages(beneficiaryImages);
    }
  }, [responseData]);

  const handleAddImages = (isUpdated) => {
    const flag = visible ? false : true;
    setVisible(flag);
    if (isUpdated === true) {
      getRequest();
    }
  };

  const items = [
    {
      key: "common",
      label: "Common Images",
      children: <Row gutter={16}>{commonImages}</Row>,
    },
    {
      key: "beneficiary",
      label: "For Particular Beneficiaries",
      children: <Row gutter={16}>{beneficiaryImages}</Row>,
    },
  ];

  return (
    <Card title="Benificiary Dashboard Images">
      <Tabs
        defaultActiveKey="common"
        activeKey={activeTab}
        items={items}
        onChange={onTabChange}
        tabBarExtraContent={
          <Button
            type="primary"
            onClick={handleAddImages}
            style={{ float: "right" }}
          >
            Add Images
          </Button>
        }
      />
      {visible && (
        <AddDashboardImages
          visible={visible}
          close={handleAddImages}
          edit={false}
          tab={activeTab}
        />
      )}
    </Card>
  );
}

export default ViewDashboardImages;
