import { Button, Card, message, Popconfirm, Space, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../../constants";
import useApi from "../../../hooks/useApi";
import TableComponent from "../../common/table/TableComponent";
import AddCompany from "./AddCompany";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { setDataToLocalStorage } from "../../../generalFunctions";

function CompanyTable() {
  const [activeTab, setActiveTab] = useState("companies");

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          {activeTab === "companies" && (
            <Space>
              <Button size="small" onClick={() => editRecord(record)}>
                <EditOutlined />
              </Button>
              <Popconfirm
                title="Delete Company"
                description={`Are you sure to delete ${record.companyName}?`}
                onConfirm={() => deleteOk(record)}
              >
                <Button size="small">
                  <DeleteOutlined style={{ color: "red" }} />
                </Button>
              </Popconfirm>
            </Space>
          )}
          {activeTab === "deactivated companies" && (
            <Space>
              <Popconfirm
                title="Reactivate Company"
                description={`Are you sure to reactivate ${record.companyName}?`}
                onConfirm={() => reactivateRecord(record)}
              >
                <Button size="small">reactivate</Button>
              </Popconfirm>
            </Space>
          )}
        </>
      ),
    },
  ];

  const [responseData, getRequest] = useApi(
    `/config/company?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );
  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState();
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteResponse, deleteRequest] = useApi();
  const [clientConfig, getClientConfig] = useApi(
    "/config",
    null,
    API_METHODS.GET
  );
  const [deactivatedCompanyData, getDeactivatedCompanies] = useApi(
    `/config/company/deactivated?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );
  const [reactivateResponse, reactivateRequest] = useApi();
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const refreshClientConfig = () => {
    getClientConfig();
  };

  useEffect(() => {
    if (clientConfig.data) {
      setDataToLocalStorage("clientConfig", clientConfig.data);
    }
  }, [clientConfig]);

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    setTableData(data);
    setTotalRecords(data && data.length > 0 ? data[0].count : 0);
  }, [responseData]);

  useEffect(() => {
    const data =
      deactivatedCompanyData.data !== BLANK_VALUE
        ? deactivatedCompanyData.data
        : [];
    setTableData(data);
    setTotalRecords(data && data.length > 0 ? data[0].count : 0);
  }, [deactivatedCompanyData]);

  useEffect(() => {
    if (deleteResponse.data) {
      getRequest();
      refreshClientConfig();
      message.success("Company Deleted successfully.");
    }
  }, [deleteResponse]);

  useEffect(() => {
    if (reactivateResponse.data) {
      getRequest();
      refreshClientConfig();
      setActiveTab("companies");
      message.success("Company Reactivated successfully.");
    }
  }, [reactivateResponse]);

  const handleAddCompany = (isUpdated) => {
    const flag = visible ? false : true;
    setVisible(flag);
    if (isUpdated === true) {
      setActiveTab("companies");
      getRequest();
      refreshClientConfig();
    }
  };

  const handleEditCompany = (isUpdated) => {
    const flag = visibleEdit ? false : true;
    !flag && setSelectedRecord(null);
    setVisibleEdit(flag);
    if (isUpdated === true) {
      getRequest();
      refreshClientConfig();
    }
  };

  const editRecord = (record) => {
    setVisibleEdit(true);
    setSelectedRecord(record);
  };

  const deleteOk = (record) => {
    deleteRequest(null, API_METHODS.DELETE, `/config/company/${record.id}`);
  };

  const reactivateRecord = (record) => {
    //Promise reqd see antd popconfirm Asynchronously close on Promise example
    reactivateRequest(
      null,
      API_METHODS.POST,
      `/config/company/reactivate/${record.id}`
    );
  };

  const onPaginationChange = (page) => {
    if (activeTab === "deactivated companies") {
      getDeactivatedCompanies(
        null,
        API_METHODS.GET,
        `/config/company/deactivated?pageNumber=${page.current}`
      );
    } else {
      getRequest(
        null,
        API_METHODS.GET,
        `/config/company?pageNumber=${page.current}`
      );
    }
    setCurrentPage(page.current);
  };

  const items = [
    {
      key: "companies",
      label: "Active Companies",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
    {
      key: "deactivated companies",
      label: "Deactivated Companies",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={deactivatedCompanyData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
  ];

  const onTabChange = (key) => {
    setActiveTab(key);
    setCurrentPage(1);
    if (key === "companies") {
      getRequest();
    } else {
      getDeactivatedCompanies();
    }
  };

  return (
    <Card title="Companies">
      <Tabs
        defaultActiveKey="companies"
        activeKey={activeTab}
        items={items}
        onChange={onTabChange}
        tabBarExtraContent={
          <Button type="primary" onClick={handleAddCompany}>
            Add Company
          </Button>
        }
      />
      {visible && (
        <AddCompany visible={visible} close={handleAddCompany} edit={false} />
      )}
      {visibleEdit && (
        <AddCompany
          visible={visibleEdit}
          selectedRecord={selectedRecord}
          close={handleEditCompany}
          edit={true}
        />
      )}
    </Card>
  );
}

export default CompanyTable;
