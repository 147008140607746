import { BLANK_VALUE } from "./constants";

export function replaceNull(jsonObject, replaceValue = BLANK_VALUE) {
  const replacedObject = (key, value) =>
    String(value) === "null" || String(value) === "undefined" || value === ""
      ? replaceValue
      : value;
  return JSON.parse(JSON.stringify(jsonObject, replacedObject));
}

export const setDataToLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getDataFromLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const getClientConfig = () => {
  return JSON.parse(localStorage.getItem("clientConfig"));
};

export const isEmpty = (value) => {
  if (
    value === null ||
    value === undefined ||
    value === BLANK_VALUE ||
    value === ""
  ) {
    return true;
  }
  return false;
};

export const getMonthFromNumber = (monthNumber) => {
  const months = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
  ];
  return months[monthNumber];
}