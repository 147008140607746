import "./App.css";
import React from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import MainLayout from "./components/MainLayout";
import Login from "./components/Login";
import UserTable from "./components/config/user/UserTable";
import Authentication from "./components/Authentication";
import Authorization from "./components/Authorization";
import PageNotFound from "./components/PageNotFound";
import CompanyTable from "./components/config/company/CompanyTable";
import ProjectTable from "./components/config/project/ProjectTable";
import { ConfigProvider } from "antd";
import MonthlyReports from "./components/monthlyReport/MonthlyReports";
import Dashboard from "./components/Dashboard";
import Reports from "./components/Reports";
import TicketTable from "./components/config/ticket/TicketTable";
import TicketDetails from "./components/config/ticket/TicketDetails";
import FCBeneficiaryRelationTable from "./components/config/fcBeneficiaryRelation/FCBeneficiaryRelationTable";
import ViewYoutubeLinks from "./components/beneficiary app ui/ViewYoutubeLinks";
import ViewDashboardImages from "./components/beneficiary app ui/ViewDashboardImages";
import BlogLinksTable from "./components/beneficiary app ui/BlogLinksTable";
import WardTable from "./components/config/ward/WardTable";
import MonitoringFormsTable from "./components/monitoringForms/MonitoringFormsTable";
import MonitoringFormDetail from "./components/monitoringForms/MonitoringFormDetail";
import AdminDashboard from "./components/AdminDashboard";
import AdminLogin from "./components/AdminLogin";
import CompanyLogin from "./components/CompanyLogin";
import CompanyDashboard from "./components/CompanyDashboard";
import CompanyReports from "./components/CompanyReports";
import ApprovalTable from "./components/approval/ApprovalTable";
import SustainableActionTable from "./components/sustainableAction/SustainableActionTable";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<>Home </>}></Route>
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route path="/company/login" element={<CompanyLogin />} />
      <Route element={<Authentication />}>
        <Route path="portal" element={<MainLayout />}>
          <Route
            element={
              <Authorization allowedRoles={["admin", "company employee"]} />
            }
          >
            <Route path="/portal/reports" element={<Reports />} />
          </Route>
          <Route
            element={<Authorization allowedRoles={["company employee"]} />}
          >
            <Route
              path="/portal/company/dashboard"
              element={<CompanyDashboard />}
            />
            <Route
              path="/portal/company/reports"
              element={<CompanyReports />}
            />
          </Route>
          <Route element={<Authorization allowedRoles={["admin"]} />}>
            {/* allowedRoles is array */}
            {/* all admin only routes here */}
            <Route
              path="/portal/admin/dashboard"
              element={<AdminDashboard />}
            />
            <Route path="/portal/user" element={<UserTable />} />
            <Route path="/portal/company" element={<CompanyTable />} />
            <Route path="/portal/ward" element={<WardTable />} />
            <Route path="/portal/project" element={<ProjectTable />} />
            <Route path="/portal/ticket" element={<TicketTable />} />
            <Route path="/portal/approval" element={<ApprovalTable />} />
            <Route path="/portal/ticket/detail" element={<TicketDetails />} />
            <Route
              path="/portal/fc-beneficiary-relation"
              element={<FCBeneficiaryRelationTable />}
            />
            <Route
              path="/portal/beneficiary-dashboard"
              element={<ViewDashboardImages />}
            />
            <Route
              path="/portal/youtube-videos"
              element={<ViewYoutubeLinks />}
            />
            <Route path="/portal/blogs" element={<BlogLinksTable />} />

            <Route
              path="/portal/monthly-reports"
              element={<MonthlyReports />}
            />

            <Route
              path="/portal/monitoring-forms"
              element={<MonitoringFormsTable />}
            />
            <Route
              path="/portal/monitoring-forms/:formId"
              element={<MonitoringFormDetail />}
            />

            <Route
              path="/portal/sustainable-actions"
              element={<SustainableActionTable />}
            />
          </Route>
          {/* add different combinations of allowedroles here*/}
        </Route>
        <Route path="unauthorized" element={<>Unauthorized</>} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </>
  )
);

function App() {
  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            headerBg: "rgb(20, 168, 0)",
            colorTextHeading: "rgb(255, 255, 255)",
          },
        },
        token: {
          algorithm: "compactAlgorithm",
          // Seed Token
          colorPrimary: "#1677ff",
          borderRadius: 2,
          borderRadius: 11,
          fontFamily: "Arial",
          colorTextBase: "#455560",
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
