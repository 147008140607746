import {
  Button,
  Card,
  Form,
  message,
  Modal,
  Popconfirm,
  Space,
  Tabs,
  Descriptions,
  QRCode,
  Row,
  Col,
  Input,
  Image,
} from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../../constants";
import useApi from "../../../hooks/useApi";
import TableComponent from "../../common/table/TableComponent";
import AddUser from "./AddUser";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { setDataToLocalStorage } from "../../../generalFunctions";
import printJS from "print-js";
import noImage from "../../../assests/images/no-image.png";
function UserTable() {
  const [activeTab, setActiveTab] = useState("admin");
  const [showQR, setShowQR] = useState(false);

  const columns = [
    {
      title: "User Name",
      dataIndex: "username",
    },
    {
      title: "Role",
      dataIndex: "role",
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          {(activeTab === "admin" ||
            activeTab === "fc" ||
            activeTab === "beneficiary" ||
            activeTab === "company-employee") && (
              <Space>
                <Button size="small" onClick={() => editRecord(record)}>
                  <EditOutlined />
                </Button>

                <Button size="small" onClick={() => updatePasswordRecord(record)}>
                  Update Password
                </Button>
                <Popconfirm
                  title="Delete User"
                  description={`Are you sure to delete ${record.username}?`}
                  onConfirm={() => deleteOk(record)}
                >
                  <Button size="small">
                    <DeleteOutlined style={{ color: "red" }} />
                  </Button>
                </Popconfirm>
                {activeTab === "beneficiary" && (
                  <Button size="small" onClick={() => viewProfile(record)}>
                    View Profile
                  </Button>
                )}
                {activeTab === "beneficiary" && (
                  <Button size="small" onClick={() => generateQR(record, true)}>
                    Show QR
                  </Button>
                )}
              </Space>
            )}
          {activeTab === "deactivated" && (
            <Space>
              <Popconfirm
                title="Reactivate User"
                description={`Are you sure to reactivate ${record.username}?`}
                onConfirm={() => reactivateRecord(record)}
              >
                <Button size="small">reactivate</Button>
              </Popconfirm>
            </Space>
          )}
        </>
      ),
    },
  ];

  const [responseData, getRequest] = useApi(
    `/config/user/admin?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );
  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState();
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteResponse, deleteRequest] = useApi();
  const [visibleUpdatePassword, setVisibleUpdatePassword] = useState(false);
  const [updatePasswordResponse, updatePasswordRequest] = useApi();
  const [visibleProfile, setVisibleProfile] = useState(false);
  const [form] = Form.useForm();
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientConfig, getClientConfig] = useApi(
    "/config",
    null,
    API_METHODS.GET
  );

  const [deactivatedUsersData, getDeactivatedUsers] = useApi(
    `/config/user/deactivated?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );
  const [reactivateResponse, reactivateRequest] = useApi();
  const [profile, profileRequest] = useApi(
    `/admin/profile/`,
    null,
    API_METHODS.GET
  );

  const [profileData, setProfileData] = useState();

  const refreshClientConfig = () => {
    getClientConfig();
  };

  useEffect(() => {
    if (clientConfig.data) {
      setDataToLocalStorage("clientConfig", clientConfig.data);
    }
  }, [clientConfig]);

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    setTableData(data);
    setTotalRecords(data && data.length > 0 ? data[0].count : 0);
  }, [responseData]);

  useEffect(() => {
    const data =
      deactivatedUsersData.data !== BLANK_VALUE
        ? deactivatedUsersData.data
        : [];
    setTableData(data);
    setTotalRecords(data && data.length > 0 ? data[0].count : 0);
  }, [deactivatedUsersData]);

  useEffect(() => {
    if (deleteResponse.data) {
      getRequest();
      refreshClientConfig();
      setActiveTab("admin");
      message.success("User Deleted successfully.");
    }
  }, [deleteResponse]);

  useEffect(() => {
    if (reactivateResponse.data) {
      getRequest();
      refreshClientConfig();
      setActiveTab("admin");
      message.success("User Reactivated successfully.");
    }
  }, [reactivateResponse]);

  const viewProfile = (record) => {
    setSelectedRecord(record);
    profileRequest(null, API_METHODS.GET, `/admin/profile/${record.id}`);
  };

  const closeProfileModal = () => {
    setVisibleProfile(false);
    setSelectedRecord(null);
    setProfileData(null);
  };

  useEffect(() => {
    if (profile.data && profile.isLoading !== true) {
      setProfileData(profile.data[0]);
      setVisibleProfile(true);
    }
  }, [profile]);

  useEffect(() => {
    if (updatePasswordResponse.data && !updatePasswordResponse.isLoading) {
      setVisibleUpdatePassword(false);
      setSelectedRecord(null);
      getRequest();
      //refreshClientConfig();
      form.resetFields();
      setActiveTab("admin");
      message.success("User Password updated successfully.");
    }
  }, [updatePasswordResponse]);

  const handleAddUser = (isUpdated) => {
    const flag = visible ? false : true;
    setVisible(flag);
    if (isUpdated === true) {
      getRequest();
      setActiveTab("admin");
      refreshClientConfig();
    }
  };

  const handleEditUser = (isUpdated) => {
    const flag = visibleEdit ? false : true;
    !flag && setSelectedRecord(null);
    setVisibleEdit(flag);
    if (isUpdated === true) {
      getRequest();
      setActiveTab("admin");
      refreshClientConfig();
    }
  };

  const editRecord = (record) => {
    setVisibleEdit(true);
    setSelectedRecord(record);
  };

  const deleteOk = (record) => {
    //Promise reqd see antd popconfirm Asynchronously close on Promise example
    deleteRequest(null, API_METHODS.DELETE, `/config/user/${record.id}`);
  };
  const reactivateRecord = (record) => {
    //Promise reqd see antd popconfirm Asynchronously close on Promise example
    reactivateRequest(
      null,
      API_METHODS.POST,
      `/config/user/reactivate/${record.id}`
    );
  };

  const onPaginationChange = (page) => {
    getRequest(
      null,
      API_METHODS.GET,
      `/config/user/${activeTab}?pageNumber=${page.current}`
    );
    setCurrentPage(page.current);
  };

  const updatePasswordRecord = (record) => {
    setVisibleUpdatePassword(true);
    setSelectedRecord(record);
  };

  const updatePassword = () => {
    form.validateFields().then((values) => {
      if (selectedRecord) {
        updatePasswordRequest(
          values,
          API_METHODS.PUT,
          `/config/user/password/${selectedRecord && selectedRecord.id}`
        );
      }
    });
  };

  const handleCancelUpdatePassword = () => {
    setVisibleUpdatePassword(false);
    setSelectedRecord(null);
    form.resetFields();
  };

  const items = [
    {
      key: "admin",
      label: "Admin",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
    {
      key: "beneficiary",
      label: "Beneficiary",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
    {
      key: "fc",
      label: "Field Co-ordinator",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
    {
      key: "company-employee",
      label: "Company Employee",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
    {
      key: "deactivated",
      label: "Deactivated Users",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={deactivatedUsersData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
  ];

  const onTabChange = (key) => {
    setActiveTab(key);
    setCurrentPage(1);
    if (key !== "deactivated") {
      getRequest(null, API_METHODS.GET, `/config/user/${key}?pageNumber=${1}`);
    } else {
      getDeactivatedUsers();
    }
  };

  const generateQR = (record) => {
    setSelectedRecord(record);
    setShowQR(true);
  };

  const downloadQRCode = () => {
    const canvas = document.getElementById("myqrcode")?.querySelector("canvas");
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement("a");
      a.download = "QRCode.png";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const printQRCode = () => {
    const canvas = document.getElementById("myqrcode")?.querySelector("canvas");
    if (canvas) {
      printJS({
        printable: canvas.toDataURL(),
        header: "Beneficiary Name: " + selectedRecord.username,
        type: "image",
        imageStyle: "width:65%",
      });
    }
  };

  return (
    <Card title="Users">
      <Tabs
        defaultActiveKey="users"
        activeKey={activeTab}
        items={items}
        onChange={onTabChange}
        tabBarExtraContent={
          <Button type="primary" onClick={handleAddUser}>
            Add User
          </Button>
        }
      />

      {visible && (
        <AddUser visible={visible} close={handleAddUser} edit={false} />
      )}
      {visibleEdit && (
        <AddUser
          visible={visibleEdit}
          selectedRecord={selectedRecord}
          close={handleEditUser}
          edit={true}
        />
      )}
      {visibleProfile && (
        <Modal
          title="Profile"
          open={visibleProfile}
          onOk={closeProfileModal}
          onCancel={closeProfileModal}
          width={700}
          destroyOnClose={true}
        >
          <Descriptions title={`${profileData.name}`}>
            {profileData.photo && (
              <Row gutter={16}>
                <Col span={24}>
                  <Image
                    width="50px"
                    height="50px"
                    src={profileData.photo}
                    placeholder={
                      <Image preview={false} src={noImage} width={100} />
                    }
                  />
                </Col>
              </Row>
            )}
            <Descriptions.Item label="Name">
              {profileData.name}
            </Descriptions.Item>
            <Descriptions.Item label="Phone">
              {profileData.phone}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {profileData.email}
            </Descriptions.Item>
            <Descriptions.Item label="Number Of Family Members">
              {profileData.familyMembers}
            </Descriptions.Item>
            <Descriptions.Item label="Occupation">
              {profileData.occupation}
            </Descriptions.Item>
            <Descriptions.Item label="Address">
              {profileData.address}
            </Descriptions.Item>
          </Descriptions>
        </Modal>
      )}
      {visibleUpdatePassword && (
        <Modal
          title={`Update Password for- ${selectedRecord.username}`}
          open={visibleUpdatePassword}
          onOk={updatePassword}
          onCancel={handleCancelUpdatePassword}
          width={800}
          okText="Update Password"
          destroyOnClose={true}
        >
          <Form layout="vertical" form={form}>
            <Row>
              <Col offset={4} span={12}>
                <Form.Item
                  name="password"
                  label="New Password"
                  rules={[
                    { required: true, message: "New Password is required." },
                    {
                      max: 20,
                      message:
                        "New Password should be maximum of 20 characters.",
                    },
                  ]}
                >
                  <Input placeholder="Password" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
      {showQR && (
        <Modal
          title={selectedRecord.username}
          width={250}
          maskClosable={false}
          open={showQR}
          footer={
            <Space>
              <Button type="primary" onClick={downloadQRCode}>
                Download
              </Button>
              <Button type="primary" onClick={printQRCode}>
                Print
              </Button>
            </Space>
          }
          onCancel={() => setShowQR(false)}
          style={{ marginTop: 25, display: "flex", justifyContent: "center" }}
        >
          <div id="myqrcode">
            <QRCode
              id="printableArea"
              color="black"
              style={{ marginTop: 20 }}
              bgColor="white"
              value={JSON.stringify({ id: selectedRecord.id })}
            />
          </div>
        </Modal>
      )}
    </Card>
  );
}

export default UserTable;
