import React, { useEffect, useState } from "react";
import { isEmpty, setDataToLocalStorage } from "../generalFunctions";
import { API_METHODS } from "../constants";
import useApi from "../hooks/useApi";
import { Collapse, Button, Card, Col, Form, Row, Select, Space, Typography, Radio } from 'antd';
import LineChart from "./Admin Dashboard/LineChart";

function CompanyDashboard() {
  const [form] = Form.useForm();
  const [projectWiseGraphData, setProjectWiseGraphData] = useState();
  const [projectWiseWasteData, setProjectWiseWasteData] = useState();
  const [filterData, setFilterData] = useState();
  const [project, setProjects] = useState();
  const [beneficiaries, setBeneficiaries] = useState();
  const [projectList, getProjectList] = useApi(
    "/company/dashboard/projects",
    null,
    API_METHODS.GET
  );

  const [beneficiariesList, getBeneficiariesList] = useApi(
    "/company/dashboard/beneficiaries",
    null,
    API_METHODS.GET
  );

  const [graphData, getGraphdata] = useApi(
    "/company/dashboard/graphs",
    null,
    API_METHODS.POST
  );

  const [waste, getWaste] = useApi(
    "/company/dashboard/waste",
    null,
    API_METHODS.POST
  );

  const filterResults = () => {
    form.validateFields().then((values) => {
      if (!isEmpty(values)) {
        setFilterData(values);
        values.period = "3m";
        getGraphdata(values);
        getWaste(values);
        setProjectWiseWasteData(null);
      }
    });
  }

  const resetFilters = () => {
    form.setFieldsValue({ "projectId": undefined, "beneficiaryId": undefined });
    setProjectWiseGraphData(null);
    getWaste();
  }

  const getGraphsDataByPeriod = (period) => {
    form.validateFields().then((values) => {
      if (!isEmpty(values)) {
        setFilterData(values);
        values.period = period;
        getGraphdata(values);
        getWaste(values);
        setProjectWiseWasteData(null);
      }
    });
  }

  useEffect(() => {
    getProjectList();
    getBeneficiariesList();
    getWaste();
  }, []);

  useEffect(() => {
    if (!isEmpty(projectList.data) && !projectList.isLoading) {
      setProjects(projectList.data);
    }
  }, [projectList]);

  useEffect(() => {
    if (!isEmpty(beneficiariesList.data) && !beneficiariesList.isLoading) {
      setBeneficiaries(beneficiariesList.data);
    }
  }, [beneficiariesList]);

  useEffect(() => {
    if (!isEmpty(graphData.data) && !graphData.isLoading) {
      if (graphData.data.length > 0) {
        setProjectWiseGraphData(graphData.data);
      } else {
        setProjectWiseGraphData(null);
      }
    }
  }, [graphData]);

  useEffect(() => {
    if (!isEmpty(waste.data) && !waste.isLoading) {
      setProjectWiseWasteData(waste.data);
    }
  }, [waste]);

  return <Space direction="vertical" size={24} style={{ width: "100%" }}>
    <Form form={form} style={{ padding: 0 }} initialValues={filterData}>
      <Row gutter={16}>
        <Col span={2}>
          <Typography.Title level={5} style={{ margin: 0 }}>Filter by: </Typography.Title>
        </Col>
        <Col span={6}>
          <Form.Item name="projectId">
            <Select
              placeholder="Select Project"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
            >
              {!isEmpty(project) && project?.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="beneficiaryId">
            <Select
              placeholder="Select Beneficiary"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
            >
              {!isEmpty(beneficiaries) && beneficiaries?.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.displayName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Space>
            <Button type='primary' onClick={filterResults}>Search</Button>
            <Button type='default' onClick={() => { setFilterData(null); resetFilters(); }}>Reset</Button>
          </Space>
        </Col>
      </Row>
    </Form>

    <Row gutter={16}>
      {projectWiseWasteData ? projectWiseWasteData?.map(projWaste =>
        <Col span={8}>
          <Card title={projWaste.name}>
            <h3>Waste: <b style={{ color: "green" }}>{projWaste.waste}</b></h3>
            <h3>Carbon: <b style={{ color: "green" }}>{projWaste.carbon}</b></h3>
            <h3>Compost: <b style={{ color: "green" }}>{projWaste.compost}</b></h3>
          </Card>
        </Col>
      ) :
        <Col span={24}>
          <Card>
            <h2 style={{ textAlign: "center" }}>Waste Data Not Available</h2>
          </Card>
        </Col>
      }
    </Row>
    {projectWiseGraphData && <Card title="Daily Health Percentage">
      <Radio.Group onChange={(e) => getGraphsDataByPeriod(e.target.value)}>
        <Radio.Button value="3m">3m</Radio.Button>
        <Radio.Button value="6m">6m</Radio.Button>
        <Radio.Button value="1y">1y</Radio.Button>
      </Radio.Group>
      <LineChart data={projectWiseGraphData} xField={"date"} yField={"health_percentage"} />
    </Card>}
  </Space>;

}

export default CompanyDashboard;
