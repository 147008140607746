import { Button, Card, message, Popconfirm, Space, Switch, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import useApi from "../../hooks/useApi";
import TableComponent from "../common/table/TableComponent";
import { DeleteOutlined } from "@ant-design/icons";
import { isEmpty } from "../../generalFunctions";
import AddBlogLinks from "./AddBlogLinks";

function BlogLinksTable() {
  const [activeTab, setActiveTab] = useState("common");
  const [commonBlogs, setCommonBlogs] = useState([]);
  const [beneficiaryBlogs, setBeneficiaryBlogs] = useState([]);
  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState();
  const [responseData, getRequest] = useApi(
    "/admin/blog",
    null,
    API_METHODS.GET
  );
  const [togglePriorityResponse, togglePriorityRequest] = useApi(
    "/admin/blog",
    null,
    API_METHODS.PUT
  );
  const [deleteResponse, deleteRequest] = useApi();
  const togglePriority = (data) => {
    let priority = data.priority ? false : true;
    let body = {
      priority: priority,
    };
    togglePriorityRequest(body, API_METHODS.PUT, `/admin/blog/${data.id}`);
  };

  const getDeleteButton = (data) => {
    return (
      <>
        <Popconfirm
          title="Delete Blog Link"
          description={`Are you sure to delete this Blog?`}
          onConfirm={() => deleteOk(data)}
        >
          <Button size="small" type="primary" danger>
            <DeleteOutlined /> Delete
          </Button>
        </Popconfirm>
      </>
    );
  };

  const [columns, setColumns] = useState([
    {
      title: "Link",
      dataIndex: "link",
      render: (text) => (
        <a href={text} target="_blank">
          {text}
        </a>
      ),
      width: 500,
    },
    {
      title: "Tittle",
      dataIndex: "tittle",
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      render: (text, record) => (
        <Space>
          <Switch
            checkedChildren="High"
            unCheckedChildren="Low"
            onClick={() => togglePriority(record)}
            checked={record.priority}
          ></Switch>
          <span style={{ float: "right" }}>{getDeleteButton(record)}</span>
        </Space>
      ),
      width: 200,
    },
  ]);

  const deleteOk = (record) => {
    deleteRequest(null, API_METHODS.DELETE, `/admin/blog/${record.id}`);
  };

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    if (deleteResponse.data) {
      getRequest();
      message.success("Blog deleted successfully.");
    }
  }, [deleteResponse]);

  useEffect(() => {
    getRequest();
  }, [togglePriorityResponse]);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    if (data && data.length) {
      let common = [];
      let forBeneficiary = [];
      for (let i = 0; i < data.length; i++) {
        if (isEmpty(data[i].beneficiaryIds)) {
          common.push(data[i]);
        } else {
          forBeneficiary.push(data[i]);
        }
      }
      setCommonBlogs(common);
      setBeneficiaryBlogs(forBeneficiary);
      setTableData(common);
    }
  }, [responseData]);

  const handleAddBlog = (isUpdated) => {
    const flag = visible ? false : true;
    setVisible(flag);
    if (isUpdated === true) {
      getRequest();
    }
  };

  const items = [
    {
      key: "common",
      label: "Common blog links",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          clientSideRender={true}
        />
      ),
    },
    {
      key: "beneficiary",
      label: "For Particular Beneficiaries",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          clientSideRender={true}
        />
      ),
    },
  ];

  const onTabChange = (key) => {
    let newColumns = [...columns];
    setActiveTab(key);
    if (key === "common") {
      setTableData(commonBlogs);
      newColumns.pop();
      newColumns.push({
        title: "Priority",
        dataIndex: "priority",
        render: (text, record) => (
          <Space>
            <Switch
              checkedChildren="High"
              unCheckedChildren="Low"
              onClick={() => togglePriority(record)}
              checked={record.priority}
            ></Switch>
            <span style={{ float: "right" }}> {getDeleteButton(record)}</span>
          </Space>
        ),
      });
    } else {
      setTableData(beneficiaryBlogs);
      newColumns.pop();
      newColumns.push({
        title: "Beneficiaries",
        dataIndex: "beneficiaries",
        render: (text, record) => (
          <Space>
            {text?.toString()}
            <span style={{ float: "right" }}>{getDeleteButton(record)}</span>
          </Space>
        ),
      });
    }
    setColumns(newColumns);
  };
  return (
    <>
      <Card title="Blogs">
        <Tabs
          defaultActiveKey="common"
          activeKey={activeTab}
          items={items}
          onChange={onTabChange}
          tabBarExtraContent={
            <Button type="primary" onClick={handleAddBlog}>
              Add Blog Links
            </Button>
          }
        />
        {visible && (
          <AddBlogLinks
            visible={visible}
            close={handleAddBlog}
            edit={false}
            tab={activeTab}
          />
        )}
      </Card>
    </>
  );
}

export default BlogLinksTable;
