import { Button, Card, message, Popconfirm, Space, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../../constants";
import useApi from "../../../hooks/useApi";
import TableComponent from "../../common/table/TableComponent";
import AddProject from "./AddProject";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { setDataToLocalStorage } from "../../../generalFunctions";

function ProjectTable() {
  const [activeTab, setActiveTab] = useState("projects");
  const columns = [
    {
      title: "Project Name",
      dataIndex: "name",
    },
    {
      title: "Location",
      dataIndex: "location",
    },
    {
      title: "Company",
      dataIndex: "companyName",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          {activeTab === "projects" && (
            <Space>
              <Button size="small" onClick={() => editRecord(record)}>
                <EditOutlined />
              </Button>
              <Popconfirm
                title="Delete Project"
                description={`Are you sure to delete ${record.name}?`}
                onConfirm={() => deleteOk(record)}
              >
                <Button size="small">
                  <DeleteOutlined style={{ color: "red" }} />
                </Button>
              </Popconfirm>
            </Space>
          )}
          {activeTab === "deactivated" && (
            <Space>
              <Popconfirm
                title="Reactivate Project"
                description={`Are you sure to reactivate ${record.name}?`}
                onConfirm={() => reactivateRecord(record)}
              >
                <Button size="small">reactivate</Button>
              </Popconfirm>
            </Space>
          )}
        </>
      ),
    },
  ];
  const [responseData, getRequest] = useApi(
    `/config/project?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );
  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState();
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteResponse, deleteRequest] = useApi();
  const [clientConfig, getClientConfig] = useApi(
    "/config",
    null,
    API_METHODS.GET
  );
  const [deactivatedProjectData, getDeactivatedProjects] = useApi(
    `/config/project/deactivated?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );
  const [reactivateResponse, reactivateRequest] = useApi();
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const refreshClientConfig = () => {
    getClientConfig();
  };

  useEffect(() => {
    if (clientConfig.data) {
      setDataToLocalStorage("clientConfig", clientConfig.data);
    }
  }, [clientConfig]);

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    setTableData(data);
    setTotalRecords(data && data.length > 0 ? data[0].count : 0);
  }, [responseData]);

  useEffect(() => {
    const data =
      deactivatedProjectData.data !== BLANK_VALUE
        ? deactivatedProjectData.data
        : [];
    setTableData(data);
    setTotalRecords(data && data.length > 0 ? data[0].count : 0);
  }, [deactivatedProjectData]);

  useEffect(() => {
    if (deleteResponse.data) {
      getRequest();
      refreshClientConfig();
      message.success("Project Deleted successfully.");
    }
  }, [deleteResponse]);

  useEffect(() => {
    if (reactivateResponse.data) {
      getRequest();
      refreshClientConfig();
      setActiveTab("projects");
      message.success("Project Reactivated successfully.");
    }
  }, [reactivateResponse]);

  const handleAddProject = (isUpdated) => {
    const flag = visible ? false : true;
    setVisible(flag);
    if (isUpdated === true) {
      getRequest();
      setActiveTab("projects");
      refreshClientConfig();
    }
  };

  const handleEditProject = (isUpdated) => {
    const flag = visibleEdit ? false : true;
    !flag && setSelectedRecord(null);
    setVisibleEdit(flag);
    if (isUpdated === true) {
      getRequest();
      refreshClientConfig();
    }
  };

  const editRecord = (record) => {
    setVisibleEdit(true);
    setSelectedRecord(record);
  };

  const onPaginationChange = (page) => {
    if (activeTab === "deactivated") {
      getDeactivatedProjects(
        null,
        API_METHODS.GET,
        `/config/project/deactivated?pageNumber=${page.current}`
      );
    } else {
      getRequest(
        null,
        API_METHODS.GET,
        `/config/project?pageNumber=${page.current}`
      );
    }
    setCurrentPage(page.current);
  };

  const deleteOk = (record) => {
    deleteRequest(null, API_METHODS.DELETE, `/config/project/${record.id}`);
  };

  const reactivateRecord = (record) => {
    //Promise reqd see antd popconfirm Asynchronously close on Promise example
    reactivateRequest(
      null,
      API_METHODS.POST,
      `/config/project/reactivate/${record.id}`
    );
  };

  const items = [
    {
      key: "projects",
      label: "Active Projects",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
    {
      key: "deactivated",
      label: "Deactivated Projects",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={deactivatedProjectData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
  ];

  const onTabChange = (key) => {
    setActiveTab(key);
    setCurrentPage(1);
    if (key === "projects") {
      getRequest();
    } else {
      getDeactivatedProjects();
    }
  };

  return (
    <Card title="Projects">
      <Tabs
        defaultActiveKey="projects"
        activeKey={activeTab}
        items={items}
        onChange={onTabChange}
        tabBarExtraContent={
          <Button type="primary" onClick={handleAddProject}>
            Add Project
          </Button>
        }
      />
      {visible && (
        <AddProject visible={visible} close={handleAddProject} edit={false} />
      )}
      {visibleEdit && (
        <AddProject
          visible={visibleEdit}
          selectedRecord={selectedRecord}
          close={handleEditProject}
          edit={true}
        />
      )}
    </Card>
  );
}

export default ProjectTable;
