export const API_METHODS = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

// export const DEFAULT_DATE_FORMAT = ["DD-MM-YYYY"];
export const BLANK_VALUE = "__";
export const EMPTY_VALUE = "";

export const ROLES = [
  //check the role names with client
  {
    key: 1,
    role: "admin",
  },
  {
    key: 2,
    role: "beneficiary",
  },
  {
    key: 3,
    role: "fc",
  },
  {
    key: 4,
    role: "company employee",
  },
];

export const IMPLEMENTING_PARTNER = "KNOW HOW FOUNDATION – INORA (KHF – INORA)";
