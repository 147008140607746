import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Select, Space, Typography } from 'antd';
import useApi from '../../hooks/useApi';
import { API_METHODS, BLANK_VALUE } from '../../constants';
import TableComponent from '../common/table/TableComponent';
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
import { getDataFromLocalStorage, isEmpty } from '../../generalFunctions';

const MonitoringFormsTable = () => {
    const [form] = Form.useForm();
    const { project, beneficiary, fc } = getDataFromLocalStorage("clientConfig");
    const [filterData, setFilterData] = useState();
    const [tableData, setTableData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const navigate = useNavigate();
    const [responseData, getRequest] = useApi(
        "/admin/monitoring-form?pageNumber=1",
        null,
        API_METHODS.GET
    );
    const columns = [
        {
            title: "Beneficiary",
            dataIndex: "beneficiaryDisplayName",
        },
        {
            title: "FC",
            dataIndex: "fcDisplayName",
        },
        {
            title: "Visiting Date",
            dataIndex: "visitingDate"
        },
        {
            title: "Company",
            dataIndex: "companyName",
        },
        {
            title: "Project",
            dataIndex: "projectName",
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <Button size="small" onClick={() => editRecord(record)}>
                    <EditOutlined />
                </Button>
            ),
        },
    ];

    const onPaginationChange = (page) => {
        let url = `/admin/monitoring-form?`;
        if (filterData){
          if (filterData.projectId) {
            url += `projectId=${filterData.projectId}`;
          }
          if (filterData.beneficiaryId) {
            url += `&beneficiaryId=${filterData.beneficiaryId}`;
          }
          if (filterData.fcId) {
            url += `&fcId=${filterData.fcId}`;
          }
        }
        url += `&pageNumber=${page.current}`;
        getRequest(
            null,
            API_METHODS.GET,
            url
        );
        setCurrentPage(page.current);
    };

    const editRecord = (record) => {
        navigate(`/portal/monitoring-forms/${record.id}`);
    }

    const filterResults = () => {
        form.validateFields().then((values) => {
            if (!isEmpty(values)) {
                setFilterData(values);
                let url = `/admin/monitoring-form?`
                if (values.projectId) {
                    url += `projectId=${values.projectId}`;
                }
                if (values.beneficiaryId) {
                    url += `&beneficiaryId=${values.beneficiaryId}`;
                }
                if (values.fcId) {
                    url += `&fcId=${values.fcId}`;
                }
                url += `&pageNumber=1`;
                getRequest(
                    null,
                    API_METHODS.GET,
                    url
                );
            }
            setCurrentPage(1);
        });
    }

    const resetFilters = () => {
        form.resetFields();
        getRequest();
    }

    useEffect(() => {
        getRequest();
    }, []);

    useEffect(() => {
        const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
        setTableData(data);
        setTotalRecords(data && data.length > 0 ? data[0].count : 0);
    }, [responseData]);

    return (
        <Card title="Monitoring Forms">
            <Form form={form} style={{ padding: 0 }} >
                <Row gutter={16}>
                    <Col span={2}>
                        <Typography.Title level={5} style={{ margin: 0 }}>Filter by: </Typography.Title>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="projectId">
                            <Select
                                placeholder="Select Project"
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: "100%" }}
                            >
                                {!isEmpty(project) && project.map((option) => (
                                    <Select.Option key={option.id} value={option.id}>
                                        {option.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="beneficiaryId">
                            <Select
                                placeholder="Select Beneficiary"
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: "100%" }}
                            >
                                {!isEmpty(beneficiary) && beneficiary.map((option) => (
                                    <Select.Option key={option.id} value={option.id}>
                                        {option.displayName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="fcId">
                            <Select
                                placeholder="Select FC"
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: "100%" }}
                            >
                                {!isEmpty(fc) && fc.map((option) => (
                                    <Select.Option key={option.id} value={option.id}>
                                        {option.displayName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Space>
                            <Button type='primary' onClick={filterResults}>Search</Button>
                            <Button type='default' onClick={() => { setFilterData(null); resetFilters(); }}>Reset</Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <TableComponent
                columns={columns}
                tableData={tableData}
                loading={responseData.isLoading}
                onPaginationChange={onPaginationChange}
                total={totalRecords}
                current={currentPage}
            />
        </Card>
    );
}

export default MonitoringFormsTable;