import React from 'react';
import ReactDOM from 'react-dom';
import { Line } from '@ant-design/plots';

const LineChart = ({ data, xField, yField }) => {
    const config = {
        data,
        xField: xField,
        yField: yField,
        point: {
            shapeField: 'square',
            sizeField: 4,
        },
        axis: {
            y: { title: "% Health" },
            x: { title: "Date" },
        },
        scale: {
            y: {
                domain: [0, 100],
            },
        },
        interaction: {
            tooltip: {
                marker: false,
            },
        },
        style: {
            lineWidth: 2,
        },
    };
    return <Line {...config} />;
}

export default LineChart;