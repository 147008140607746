import React, { useEffect, useState } from "react";
import useApi from "../hooks/useApi";
import { API_METHODS, BLANK_VALUE } from "../constants";
import { Alert, Button, Card, Col, Form, Row, Space } from "antd";

function CompanyReports() {
  const [reportData, setReportData] = useState();
  const [reportBody, setReportBody] = useState();
  const [reports, getReports] = useApi(
    "/company/report?pageNumber=1",
    null,
    API_METHODS.GET
  );

  const printReport = () => {
    const canvas = document.getElementById("summary-report");
    let ifram = document.createElement("iframe");
    ifram.style = "display:none";
    document.body.appendChild(ifram);
    let pri = ifram.contentWindow;
    pri.document.open();
    pri.document.write(canvas.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  useEffect(() => {
    getReports();
  }, []);

  useEffect(() => {
    const data = reports.data !== BLANK_VALUE ? reports.data : "";
    setReportData(data);
  }, [reports]);

  return <Row gutter={16}>
    <Col span={4}>
      <Card title="Report Month" style={{ height: "100vh" }}>
        <Space direction='vertical' style={{ width: "100%" }}>
          {reportData && reportData.map(report => <Alert onClick={() => { setReportBody(report.reportText); }}
            message={report.monthYear?.toUpperCase()}
            // description="Success Description Success Description Success Description"
            type="success"
          />)}
        </Space>
      </Card>
    </Col>
    <Col span={20}>
      {reportBody && <Card
        extra={
          <>
            {reportData &&
              <Button type="default" onClick={printReport}>
                Print Report
              </Button>
            }
          </>
        }>
        <div id="summary-report" dangerouslySetInnerHTML={{ __html: reportBody }} style={{
          float: 'left',
          width: "100%",
          overflowY: 'auto',
          height: 750,
        }} />
      </Card>}
    </Col>
  </Row>;
}

export default CompanyReports;
