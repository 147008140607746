import { Button, Card, message, Popconfirm, Space, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../../constants";
import useApi from "../../../hooks/useApi";
import TableComponent from "../../common/table/TableComponent";
import AddFCBeneficiaryRelation from "./AddFCBeneficiaryRelation";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { setDataToLocalStorage } from "../../../generalFunctions";

function FCBeneficiaryRelationTable() {
  const [activeTab, setActiveTab] = useState("active");

  const columns = [
    {
      title: "FC",
      dataIndex: "fcDisplayName",
    },
    {
      title: "Beneficiary",
      dataIndex: "beneficiaryDisplayName",
    },
    {
      title: "Show Form",
      dataIndex: "showForm",
      render: (text) => <>{JSON.stringify(text)}</>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          {activeTab === "active" && (
            <Space>
              <Button size="small" onClick={() => editRecord(record)}>
                <EditOutlined />
              </Button>
              <Popconfirm
                title="Delete Relation"
                description={`Are you sure to delete?`}
                onConfirm={() => deleteOk(record)}
              >
                <Button size="small">
                  <DeleteOutlined style={{ color: "red" }} />
                </Button>
              </Popconfirm>
            </Space>
          )}
          {activeTab === "deactivated" && (
            <Space>
              <Popconfirm
                title="Reactivate Relation"
                description={`Are you sure to reactivate?`}
                onConfirm={() => reactivateRecord(record)}
              >
                <Button size="small">reactivate</Button>
              </Popconfirm>
            </Space>
          )}
        </>
      ),
    },
  ];

  const [responseData, getRequest] = useApi(
    "/config/fc-beneficiary-relation",
    null,
    API_METHODS.GET
  );
  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteResponse, deleteRequest] = useApi();
  const [clientConfig, getClientConfig] = useApi(
    "/config",
    null,
    API_METHODS.GET
  );
  const [deactivatedRelations, getDeactivatedRelations] = useApi(
    "/config/fc-beneficiary-relation/deactivated",
    null,
    API_METHODS.GET
  );
  const [reactivateResponse, reactivateRequest] = useApi();

  const refreshClientConfig = () => {
    getClientConfig();
  };

  useEffect(() => {
    if (clientConfig.data) {
      setDataToLocalStorage("clientConfig", clientConfig.data);
    }
  }, [clientConfig]);

  useEffect(() => {
    getRequest(
      null,
      API_METHODS.GET,
      `/config/fc-beneficiary-relation?pageNumber=${1}`
    );
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    setTableData(data);
    setTotalRecords(data && data.length > 0 ? data[0].count : 0);
  }, [responseData]);

  useEffect(() => {
    const data =
      deactivatedRelations.data !== BLANK_VALUE
        ? deactivatedRelations.data
        : [];
    setTableData(data);
    setTotalRecords(data && data.length > 0 ? data[0].count : 0);
  }, [deactivatedRelations]);

  useEffect(() => {
    if (deleteResponse.data) {
      getRequest(
        null,
        API_METHODS.GET,
        `/config/fc-beneficiary-relation?pageNumber=${1}`
      );
      refreshClientConfig();
      message.success("Relation deleted successfully.");
    }
  }, [deleteResponse]);

  useEffect(() => {
    if (reactivateResponse.data) {
      getRequest(
        null,
        API_METHODS.GET,
        `/config/fc-beneficiary-relation?pageNumber=${1}`
      );
      refreshClientConfig();
      setActiveTab("active");
      message.success("Relation reactivated successfully.");
    }
  }, [reactivateResponse]);

  const handleAddRelation = (isUpdated) => {
    const flag = visible ? false : true;
    setVisible(flag);
    if (isUpdated === true) {
      getRequest(
        null,
        API_METHODS.GET,
        `/config/fc-beneficiary-relation?pageNumber=${1}`
      );
      setActiveTab("active");
      refreshClientConfig();
    }
  };

  const handleEditRelation = (isUpdated) => {
    const flag = visibleEdit ? false : true;
    !flag && setSelectedRecord(null);
    setVisibleEdit(flag);
    if (isUpdated === true) {
      getRequest(
        null,
        API_METHODS.GET,
        `/config/fc-beneficiary-relation?pageNumber=${1}`
      );
      refreshClientConfig();
    }
  };

  const editRecord = (record) => {
    setVisibleEdit(true);
    setSelectedRecord(record);
  };

  const deleteOk = (record) => {
    deleteRequest(
      null,
      API_METHODS.DELETE,
      `/config/fc-beneficiary-relation/${record.id}`
    );
  };

  const reactivateRecord = (record) => {
    //Promise reqd see antd popconfirm Asynchronously close on Promise example
    reactivateRequest(
      null,
      API_METHODS.POST,
      `/config/fc-beneficiary-relation/reactivate/${record.id}`
    );
  };

  const onPaginationChange = (page) => {
    setCurrentPage(page.current);
    if (activeTab === "active") {
      getRequest(
        null,
        API_METHODS.GET,
        `/config/fc-beneficiary-relation?pageNumber=${page.current}`
      );
    } else if (activeTab === "deactivated") {
      getDeactivatedRelations(
        null,
        API_METHODS.GET,
        `/config/fc-beneficiary-relation/deactivated?pageNumber=${page.current}`
      );
    }
  };

  const items = [
    {
      key: "active",
      label: "Active Relations",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
    {
      key: "deactivated",
      label: "Deactivated Relations",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={deactivatedRelations.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
  ];

  const onTabChange = (key) => {
    setCurrentPage(1);
    setActiveTab(key);
    if (key === "active") {
      getRequest(
        null,
        API_METHODS.GET,
        `/config/fc-beneficiary-relation?pageNumber=${1}`
      );
    } else {
      getDeactivatedRelations(
        null,
        API_METHODS.GET,
        `/config/fc-beneficiary-relation/deactivated?pageNumber=${1}`
      );
    }
  };

  return (
    <div>
      <Card title="Fc Beneficiary Relation">
        <Tabs
          defaultActiveKey="active"
          activeKey={activeTab}
          items={items}
          onChange={onTabChange}
          tabBarExtraContent={
            <Button type="primary" onClick={handleAddRelation}>
              Add Relation
            </Button>
          }
        />
        {visible && (
          <AddFCBeneficiaryRelation
            visible={visible}
            close={handleAddRelation}
            edit={false}
          />
        )}
        {visibleEdit && (
          <AddFCBeneficiaryRelation
            visible={visibleEdit}
            selectedRecord={selectedRecord}
            close={handleEditRelation}
            edit={true}
          />
        )}
      </Card>
    </div>
  );
}

export default FCBeneficiaryRelationTable;
