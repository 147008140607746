import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Col, Row, message, Select } from "antd";
import { API_METHODS, ROLES } from "../../../constants";
import useApi from "../../../hooks/useApi";
import { getClientConfig, isEmpty } from "../../../generalFunctions";

function AddUser(props) {
  const [form] = Form.useForm();
  const { visible, close, selectedRecord, edit } = props;
  const [response, request] = useApi("/config/user", null, API_METHODS.POST);
  const [editResponse, editRequest] = useApi(
    `/config/user/${selectedRecord && selectedRecord.id}`,
    null,
    API_METHODS.PUT
  );

  const { company, project, ward } = getClientConfig();
  const [selectedRole, setSelectedRole] = useState();
  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("User added successfully.");
    }
  }, [response]);

  useEffect(() => {
    if (editResponse.data) {
      close(true);
      message.success("User updated successfully.");
    }
  }, [editResponse]);

  useEffect(() => {
    if (selectedRecord) {
      setSelectedRole(selectedRecord.role);
      form.setFieldsValue(selectedRecord);
      let filteredProjects = project.filter(
        (a) => a.companyId === selectedRecord.companyId
      );
      setFilteredProjects(filteredProjects);
    }
  }, [selectedRecord]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (selectedRecord) {
          editRequest(values);
        } else {
          request(values, API_METHODS.POST);
        }
      })
      .catch((e) => {});
  };

  const handleCancel = () => {
    close();
  };

  const roleSelected = (role) => {
    setSelectedRole(role);
    form.setFieldValue("projectId");
    form.setFieldValue("wardId");
  };

  const companySelected = (company) => {
    if (!isEmpty(project)) {
      let filteredProjects = project.filter((a) => a.companyId === company);
      form.setFieldValue("projectId");
      setFilteredProjects(filteredProjects);
    }
  };

  return (
    <>
      <Modal
        title={selectedRecord ? "Update User" : "Add User"}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        okText="Save"
      >
        <Form layout="vertical" form={form}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="username"
                label="Username"
                rules={[
                  { required: true, message: "Username is required." },
                  {
                    max: 25,
                    message: "Username should be maximum of 25 characters.",
                  },
                ]}
              >
                <Input placeholder="Username" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="displayName"
                label="Display Name"
                rules={[
                  { required: true, message: "Display Name is required." },
                  {
                    max: 100,
                    message: "Username should be maximum of 100 characters.",
                  },
                ]}
              >
                <Input placeholder="Display Name" />
              </Form.Item>
            </Col>
            {!edit && (
              <Col span={8}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    { required: true, message: "Password is required." },
                    {
                      max: 20,
                      message: "Password should be maximum of 20 characters.",
                    },
                  ]}
                >
                  <Input placeholder="Password" />
                </Form.Item>
              </Col>
            )}
            <Col span={8}>
              <Form.Item
                name="role"
                label="Role"
                rules={[{ required: true, message: "Role is required." }]}
              >
                <Select
                  placeholder="Select Role"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={roleSelected}
                >
                  {ROLES.map((option) => (
                    <Select.Option key={option.id} value={option.role}>
                      {option.role}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {selectedRole && selectedRole !== "admin" && (
              <Col span={8}>
                <Form.Item
                  name="companyId"
                  label="Company"
                  rules={[{ required: true, message: "Company is required." }]}
                >
                  <Select
                    placeholder="Select Company"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={companySelected}
                  >
                    {!isEmpty(company) &&
                      company.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                          {option.companyName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {selectedRole && selectedRole === "beneficiary" && (
              <>
                <Col span={8}>
                  <Form.Item
                    name="projectId"
                    label="Project"
                    rules={[
                      { required: true, message: "Project is required." },
                    ]}
                  >
                    <Select
                      placeholder="Select Project"
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {filteredProjects.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="wardId"
                    label="Ward"
                    rules={[{ required: true, message: "Ward is required." }]}
                  >
                    <Select
                      placeholder="Select Ward"
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {!isEmpty(ward) && ward.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default AddUser;
