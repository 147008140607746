import React from "react";
import CompanyReports from "./CompanyReports";
import AdminReports from "./AdminReports";

function Reports() {
  let user = JSON.parse(localStorage.getItem("user"));
  return user.role === "admin" ? <AdminReports /> : <CompanyReports />;
}

export default Reports;
