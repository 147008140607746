import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Image, Input, List, Radio, Space, message } from 'antd';
import useApi from '../../hooks/useApi';
import { API_METHODS, BLANK_VALUE } from '../../constants';
import { useNavigate, useParams } from 'react-router-dom';
import { getClientConfig, isEmpty } from '../../generalFunctions';

const MonitoringFormDetail = (props) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { monitoring_questions } = getClientConfig();
    const [monitoringDetails, setMonitoringDetails] = useState();
    const { formId } = useParams();
    const [responseData, getRequest] = useApi(
        `/admin/monitoring-form/${formId}`,
        null,
        API_METHODS.GET
    );
    const [updateResponseData, updateRequest] = useApi(
        `/admin/monitoring-form/${formId}`,
        null,
        API_METHODS.PUT,
        true
    );

    const updateMonitoringForm = () => {
        const values = form.getFieldsValue();
        let answers = { ...values };
        delete answers["comments"];
        const answerKeys = Object.keys(answers);
        const answerValues = Object.values(answers);
        answers = answerKeys.map((key, index) => { return { id: key, value: answerValues[index] } });

        const formData = new FormData();
        for (const name in values) {
            if (name === "images") {
                if (values["images"] != undefined) {
                    //appending multi images to form data
                    for (let i = 0; i < values["images"].fileList.length; i++) {
                        formData.append(
                            "attachments",
                            values["images"].fileList[i].originFileObj,
                            values["images"].fileList[i].name
                        );
                    }
                }
            }
        }
        formData.append("comments", values.comments);
        formData.append("answers", JSON.stringify(answers));

        updateRequest(formData);
    }

    useEffect(() => {
        if (!responseData.isLoading) {
            const data = responseData.data !== BLANK_VALUE ? responseData.data : [];

            if (data) {
                setMonitoringDetails(data[0]);
                const answers = [];
                data[0].answers.forEach((answer, index) => {
                    answers[index + 1] = answer.value;
                });
                let initialValues = { ...answers };
                initialValues.comments = data[0].comments;
                form.setFieldsValue(initialValues);
            }
        }
    }, [responseData]);

    useEffect(() => {
        if (updateResponseData.data && !updateResponseData.isLoading && isEmpty(updateResponseData.error)) {
            message.info("Monitoring form updated successfully.");
            navigate(-1);
        }
    }, [updateResponseData]);

    useEffect(() => {
        getRequest();
    }, []);

    return (
        <Card title={"Monitoring Form -" + monitoringDetails?.beneficiaryDisplayName + ": " + monitoringDetails?.visitingDate}>
            {monitoring_questions?.length > 0 &&
                <Form form={form} layout='vertical'>
                    <List
                        grid={{
                            gutter: 16,
                            column: 1
                        }}
                        dataSource={monitoring_questions}
                        renderItem={(item) => (
                            <List.Item>
                                <Card title={item.questionNumber + ". " + item.question}>
                                    {
                                        // item.isRatingQuestion ?
                                        //     <Form.Item label="" name={item.questionNumber}>
                                        //         <Radio.Group key={item.questionNumber}>
                                        //             <Space>
                                        //                 {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((option, index) =>
                                        //                     <Radio value={option}>{option}</Radio>
                                        //                 )
                                        //                 }
                                        //             </Space>
                                        //         </Radio.Group>
                                        //     </Form.Item>
                                        //     :
                                            <Form.Item label="" name={item.questionNumber}>
                                                <Radio.Group key={item.questionNumber}>
                                                    <Space>
                                                        {item.answer.map((option, index) =>
                                                            <Radio value={option.id}>{option.answer}</Radio>
                                                        )
                                                        }
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                    }
                                </Card>
                            </List.Item>
                        )}
                    />
                    <Form.Item label="Comments" name="comments">
                        <Input.TextArea rows={4} />
                    </Form.Item>
                   { monitoringDetails?.images?.length > 0 && (<Card title={"Images"}>
                    <Col span={22}>
                        <Space>
                            {monitoringDetails.images.map(image =>
                                <Space direction='vertical' style={{ width: "100%" }}>
                                    <Image
                                        width={100}
                                        height={100}
                                        src={image}
                                        style={{ marginLeft: 10 }}
                                        placeholder={
                                            <Image preview={false} src={image} width={100} style={{ marginLeft: 10 }} />
                                        }
                                    />
                                </Space>)}
                        </Space>
                    </Col>
                </Card>)}
                </Form>
            }
            <Space style={{ float: "right" }}>
                <Button
                    type="default"
                    onClick={() => navigate(-1)}
                >
                    Cancel
                </Button>
                <Button
                    type="primary"
                    onClick={updateMonitoringForm}
                >
                    Update
                </Button>
            </Space>
        </Card>
    );
}

export default MonitoringFormDetail;