import { Card, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import useApi from "../../hooks/useApi";
import TableComponent from "../common/table/TableComponent";
import { useNavigate } from "react-router-dom";
function ApprovalTable() {
  const [activeTab, setActiveTab] = useState("open");
  const getApprovalStatus = (text) => {
    if (text == 0) {
      return "Requested";
    } else if (text == 1) {
      return "Accepted";
    } else if (text == 2) {
      return "Declined";
    }
  };
  const columns = [
    {
      title: "Beneficiary",
      dataIndex: "beneficiaryDisplayName",
    },
    {
      title: "FC",
      dataIndex: "fcDisplayName",
    },
    {
      title: "Date Time",
      dataIndex: "datetime",
    },
    {
      title: "Status",
      dataIndex: "approvalStatus",
      render: (text) => getApprovalStatus(text),
    },
  ];

  const [responseData, getRequest] = useApi(
    "/admin/approval/open",
    null,
    API_METHODS.GET
  );

  const [closedApprovals, getClosedApprovals] = useApi(
    "/admin/approval/closed",
    null,
    API_METHODS.GET
  );

  const [tableData, setTableData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    getRequest(null, API_METHODS.GET, `/admin/approval/open?pageNumber=${1}`);
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    setTableData(data);
    setTotalRecords(data && data.length > 0 ? data[0].count : 0);
  }, [responseData]);

  useEffect(() => {
    const data =
      closedApprovals.data !== BLANK_VALUE ? closedApprovals.data : [];
    setTableData(data);
    setTotalRecords(data && data.length > 0 ? data[0].count : 0);
  }, [closedApprovals]);

  const onPaginationChange = (page) => {
    setCurrentPage(page.current);
    if (activeTab === "open") {
      getRequest(
        null,
        API_METHODS.GET,
        `/admin/approval/open?pageNumber=${page.current}`
      );
    } else if (activeTab === "closed") {
      getClosedApprovals(
        null,
        API_METHODS.GET,
        `/admin/approval/closed?pageNumber=${page.current}`
      );
    }
  };

  const items = [
    {
      key: "open",
      label: "Open",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
    {
      key: "closed",
      label: "Closed",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={closedApprovals.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
  ];

  const onTabChange = (key) => {
    setActiveTab(key);
    setCurrentPage(1);
    if (key === "open") {
      getRequest(null, API_METHODS.GET, `/admin/approval/open?pageNumber=${1}`);
    } else {
      getClosedApprovals(
        null,
        API_METHODS.GET,
        `/admin/approval/closed?pageNumber=${1}`
      );
    }
  };

  return (
    <Card title="Approval Requests">
      <Tabs
        defaultActiveKey="open"
        activeKey={activeTab}
        items={items}
        onChange={onTabChange}
      />
    </Card>
  );
}

export default ApprovalTable;
