import React, { useState } from "react";
import { ConfigProvider, Layout, Menu } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  SettingOutlined,
  LogoutOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import {
  Link,
  Outlet,
  // useLocation,
  useNavigate,
} from "react-router-dom";

// import logo from "../assests/images/logo.png";

const { Header, Sider, Content } = Layout;

function MainLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  // const location = useLocation();

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const logoutClicked = () => {
    //TODO: api call for token cancellation
    let user = JSON.parse(localStorage.getItem("user"));
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    localStorage.removeItem("clientConfig");
    if (user.role === "admin") {
      navigate("/admin/login");
    } else {
      navigate("/company/login");
    }
  };

  // const refreshClientConfig = () => {
  //   getClientConfig();
  // };

  const isAllowed = (allowedRoles) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let userRole = user ? user.role : null;
    let isAllowed = false;
    if (userRole) {
      isAllowed = allowedRoles.find((role) => role === userRole);
    }
    return isAllowed;
  };

  const items = [
    {
      label: "Dashboard",
      path:
        JSON.parse(localStorage.getItem("user")).role === "admin"
          ? "admin/dashboard"
          : "company/dashboard",
      key: "dashboard",
      icon: <HomeOutlined />,
    },
    {
      label: "Summary Report",
      path: "monthly-reports",
      key: "monthly-reports",
      icon: <FileTextOutlined />,
      permission: ["admin"],
    },
    {
      label: "Monitoring Forms",
      path: "monitoring-forms",
      key: "monitoring-forms",
      icon: <FileTextOutlined />,
      permission: ["admin"],
    },
    {
      label: "Sustainable Actions",
      path: "sustainable-actions",
      key: "sustainable-actions",
      icon: <FileTextOutlined />,
      permission: ["admin"],
    },
    {
      label: "Ticket",
      path: "ticket",
      key: "ticket",
      icon: <FileTextOutlined />,
      permission: ["admin"],
    },
    {
      label: "Approvals",
      path: "approval",
      key: "approval",
      icon: <FileTextOutlined />,
      permission: ["admin"],
    },
    {
      label: "Beneficiary App",
      key: "Beneficiary",
      icon: <SettingOutlined />,
      permission: ["admin"],
      children: [
        {
          label: "Dashboard Images",
          path: "beneficiary-dashboard",
          key: "beneficiary-dashboard",
          permission: ["admin"],
        },
        {
          label: "Youtube Videos",
          path: "youtube-videos",
          key: "youtube-videos",
          permission: ["admin"],
        },
        {
          label: "Blogs",
          path: "blogs",
          key: "blogs",
          permission: ["admin"],
        },
      ],
    },
    {
      label: "Settings",
      key: "Settings",
      icon: <SettingOutlined />,
      permission: ["admin"],
      children: [
        {
          label: "User",
          path: "user",
          key: "user",
          permission: ["admin"],
        },
        {
          label: "Company",
          path: "company",
          key: "company",
          permission: ["admin"],
        },
        {
          label: "FC-Beneficiary",
          path: "fc-beneficiary-relation",
          key: "fc-beneficiary-relation",
          permission: ["admin"],
        },
        {
          label: "Ward",
          path: "ward",
          key: "ward",
          permission: ["admin"],
        },
        {
          label: "Project",
          path: "project",
          key: "project",
          permission: ["admin"],
        },
      ],
    },
    {
      label: "Reports",
      path: "company/reports",
      key: "company-reports",
      icon: <FileTextOutlined />,
      permission: ["company employee"],
    },
    {
      label: "Log Out",
      key: "logout",
      icon: <LogoutOutlined />,
      onClick: logoutClicked,
    },
  ];

  let href = window.location.href.split("/");
  href = href.slice(-1);

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed} theme="light">
        {/* <div className="logo">
          <img style={{ height: 50, width: "100%" }} src={logo} />
        </div> */}
        <ConfigProvider
          theme={{
            token: {
              fontSize: 14,
              itemBorderRadius: 16,
            },
          }}
        >
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={href}
            selectedKeys={href}
            items={items.map((l1_item, index) => {
              let return_status = 0;
              if (l1_item?.permission) {
                if (isAllowed(l1_item?.permission)) {
                  return_status = 1;
                }
              } else {
                return_status = 1;
              }
              return (
                return_status && {
                  ...l1_item,
                  label: l1_item?.path ? (
                    <Link to={l1_item?.path}>{l1_item?.label}</Link>
                  ) : (
                    <>{l1_item?.label}</>
                  ),
                  children: l1_item?.children?.map((l2_item, l2_index) => {
                    let return_status = 0;
                    if (l2_item?.permission) {
                      if (isAllowed(l2_item?.permission)) {
                        return_status = 1;
                      }
                    } else {
                      return_status = 1;
                    }
                    return (
                      return_status && {
                        ...l2_item,
                        label: <Link to={l2_item?.path}>{l2_item?.label}</Link>,
                      }
                    );
                  }),
                }
              );
            })}
          ></Menu>
        </ConfigProvider>
      </Sider>
      <Layout
        className="site-layout"
        style={{
          minHeight: "100vh",
          width: "100%",
        }}
      >
        <Header style={{ padding: 0 }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: toggle,
            }
          )}
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
