import React, { useEffect, useState } from "react";
import { Modal, Form, Col, Row, message, Select, Radio } from "antd";
import { API_METHODS } from "../../../constants";
import useApi from "../../../hooks/useApi";
import { getClientConfig, isEmpty } from "../../../generalFunctions";

function AddFCBeneficiaryRelation(props) {
  const [form] = Form.useForm();
  const { visible, close, selectedRecord } = props;
  const { fc, beneficiary } = getClientConfig();
  const [company, setCompany] = useState();
  const [beneficiaryList, setBeneficiaryList] = useState([]);

  const [response, request] = useApi(
    "/config/fc-beneficiary-relation",
    null,
    API_METHODS.POST
  );
  const [editResponse, editRequest] = useApi(
    `/config/fc-beneficiary-relation/${selectedRecord && selectedRecord.id}`,
    null,
    API_METHODS.PUT
  );

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("Relation added successfully.");
    }
  }, [response]);

  useEffect(() => {
    if (editResponse.data) {
      close(true);
      message.success("Relation updated successfully.");
    }
  }, [editResponse]);

  useEffect(() => {
    if (selectedRecord) {
      let beneficiaries = beneficiary.filter(
        (element) => element.companyId === selectedRecord.companyId
      );
      setCompany(selectedRecord.companyId);
      setBeneficiaryList(beneficiaries);
      form.setFieldsValue(selectedRecord);
    }
  }, [selectedRecord]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (selectedRecord) {
          values.companyId = company;
          editRequest(values);
        } else {
          values.companyId = company;
          request(values, API_METHODS.POST);
        }
      })
      .catch((e) => {});
  };

  const handleCancel = () => {
    close();
  };

  const fcSelected = (e) => {
    let companyId = fc.find((element) => element.id === e).companyId;
    let beneficiaries = !isEmpty(beneficiary)
      ? beneficiary.filter((element) => element.companyId === companyId)
      : [];
    form.resetFields(["beneficiaryId"]);
    setCompany(companyId);
    setBeneficiaryList(beneficiaries);
  };

  return (
    <>
      <Modal
        title={selectedRecord ? "Update Relation" : "Add Relation"}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        okText="Save"
      >
        <Form
          layout="vertical"
          form={form}
          // initialValues={{ showForm: false }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="fcId"
                label="FC"
                rules={[{ required: true, message: "FC is required." }]}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={fcSelected}
                >
                  {!isEmpty(fc) &&
                    fc.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option.displayName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="beneficiaryId"
                label="Beneficiary"
                rules={[
                  { required: true, message: "Beneficiary is required." },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {beneficiaryList.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.displayName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="showForm" label="Show Form">
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default AddFCBeneficiaryRelation;
