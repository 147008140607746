import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  message,
  Upload,
  Tabs,
  Select,
  Space,
} from "antd";
import { API_METHODS } from "../../constants";
import { UploadOutlined } from "@ant-design/icons";
import { getClientConfig, isEmpty } from "../../generalFunctions";
import useApi from "../../hooks/useApi";
import { useNavigate } from "react-router-dom";

function AddDashboardImages(props) {
  const { visible, close, tab } = props;
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState(tab);
  const { beneficiary } = getClientConfig();
  const [responseAddImages, postAddImages] = useApi(
    `/admin/dashboard-images`,
    null,
    API_METHODS.POST,
    true
  );

  const handleCancel = () => {
    close();
  };

  const uploadProps = {
    beforeUpload: (file) => {
      const isImage = file.type === "image/png" || file.type === "image/jpeg";
      if (!isImage) {
        message.error(`${file.name} error. Only PNG and JPEG files supported`);
      }
      return isImage || Upload.LIST_IGNORE;
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const uploadButton = (
    <div>
      <UploadOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const saveImages = async () => {
    form
      .validateFields()
      .then((values) => {
        const formData = new FormData();
        for (const name in values) {
          if (name === "upload") {
            if (values["upload"] != undefined) {
              //appending multi images to form data
              for (let i = 0; i < values["upload"].fileList.length; i++) {
                formData.append(
                  "images",
                  values["upload"].fileList[i].originFileObj,
                  values["upload"].fileList[i].name
                );
              }
            }
          } else {
            if (activeTab === "beneficiary") {
              formData.append(name, values[name]);
            }
          }
        }
        postAddImages(formData);
      })
      .catch((e) => {});;
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const onTabChange = (key) => {
    form.resetFields();
    setActiveTab(key);
    setFileList([]);
  };

  const items = [
    {
      key: "common",
      label: "Common Images",
      children: (
        <Form layout="vertical" form={form}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Form.Item name="upload" label="Upload Images">
              <Upload.Dragger
                {...uploadProps}
                customRequest={dummyRequest}
                listType="picture-card"
                fileList={fileList}
                onChange={handleChange}
                multiple={true}
              >
                {<><p className="ant-upload-drag-icon">
                  <UploadOutlined />
                </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                  </p></>}
              </Upload.Dragger>
            </Form.Item>
          </Space>
        </Form>
      ),
    },
    {
      key: "beneficiary",
      label: "For Particular Beneficiaries",
      children: (
        <Form layout="vertical" form={form}>
          <Form.Item name="beneficiaryIds" label="Beneficiaries">
            <Select
              mode="multiple"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {!isEmpty(beneficiary) && beneficiary.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.displayName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="upload" label="Upload Images">
            <Upload.Dragger
              {...uploadProps}
              customRequest={dummyRequest}
              listType="picture-card"
              fileList={fileList}
              onChange={handleChange}
              multiple={true}
            >
              {<><p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload.
                </p></>}
            </Upload.Dragger>
          </Form.Item>
        </Form>
      ),
    },
  ];

  useEffect(() => {
    if (responseAddImages.data) {
      message.success("Images Added successfully.");
      close(true);
    } else if (responseAddImages.error) {
      message.success("Please try again.");
    }
  }, [responseAddImages]);


  return (
    <Modal
      title={"Add Images"}
      open={visible}
      onOk={saveImages}
      onCancel={handleCancel}
      width={1000}
      okText="Save"
    >
      <Tabs
        defaultActiveKey="common"
        activeKey={activeTab}
        items={items}
        onChange={onTabChange}
      />
    </Modal>
  );
}

export default AddDashboardImages;
