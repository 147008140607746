import React from 'react';
import { Editor } from "@tinymce/tinymce-react";

const RichTextEditor = ({ editorRef, height, initialValue, isViewOnly }) => {

    const base64_img_handler = (blobInfo) => new Promise((resolve) => {
        resolve("data:image/png;base64," + blobInfo.base64());
    });

    return (
        <Editor
            apiKey='lq6qrrozdxamd85voildhy9lizsg6lh5meugxj7n5rj2lly2'
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={initialValue}
            init={{
                height: height ? height : 700,
                menubar: false,
                images_upload_handler: base64_img_handler,
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                ],
                toolbar: !isViewOnly ? 'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'table |tablecellbackgroundcolor |' +
                    'image link|' +
                    'print preview fullscreen |' +
                    'help'
                    : 'print preview fullscreen |',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
        />
    );
}

export default RichTextEditor;