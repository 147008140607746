import {
  Card,
  Col,
  Collapse,
  Descriptions,
  Row,
  Form,
  Input,
  Image,
  Upload,
  message,
  Button,
  Space,
  Tag,
  Divider,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import useApi from "../../../hooks/useApi";
import { API_METHODS } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import noImage from "../../../assests/images/no-image.png";
import RichTextEditor from "../../common/RTE/RichTextEditor";
const { Title, Paragraph, Text, Link } = Typography;

const { TextArea } = Input;

function TicketDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const editorRef = useRef(null);
  const [ticketData, getTicketData] = useApi(
    "/admin/ticket/",
    null,
    API_METHODS.GET
  );
  const [newTicket, putNewTicketReply] = useApi(
    `/admin/ticket/${location.state.ticket_id}`,
    null,
    API_METHODS.PUT,
    true
  );
  const [items, setItems] = useState([]);
  const [form] = Form.useForm();
  const [ticketDetails, setTicketDetails] = useState([]);
  const [fileList, setFileList] = useState([]);
  const uploadProps = {
    beforeUpload: (file) => {
      const isImage = file.type === "image/png" || file.type === "image/jpeg";
      if (!isImage) {
        message.error(`${file.name} error. Only PNG and JPEG files supported`);
      }
      return isImage || Upload.LIST_IGNORE;
    },
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  useEffect(() => {
    if (location.state && location.state.ticket_id) {
      getTicketData(
        null,
        API_METHODS.GET,
        `/admin/ticket/${location.state.ticket_id}`
      );
    }
  }, [location.state]);

  useEffect(() => {
    let initialValues = ticketData && ticketData.data && ticketData.data[0];
    if (initialValues) {
      setTicketDetails(initialValues);
      let conversations = initialValues.conversation;
      let items = [];
      for (let i = 0; i < conversations.length; i++) {
        let images = [];
        if (conversations[i].attachments) {
          for (let j = 0; j < conversations[i].attachments.length; j++) {
            images.push(
              <>
                <Image
                  width={100}
                  height={100}
                  src={conversations[i].attachments[j]}
                  placeholder={
                    <Image preview={false} src={noImage} width={100} />
                  }
                />
              </>
            );
          }
        }
        items.push({
          key: i,
          label: conversations[i].timestamp,
          children: (
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: conversations[i].details.includes("/r")
                    ? conversations[i].details.replace(/\r\n/g, "<br />")
                    : conversations[i].details.replace(/\n/g, "<br />"),
                }}
              />
              {/* <Paragraph>{conversations[i].details}</Paragraph> */}
              {images?.length > 0 && (
                <>
                  <Divider />
                  <p>
                    <Image.PreviewGroup>
                      <Space size={15}> {images}</Space>
                    </Image.PreviewGroup>
                  </p>
                </>
              )}
            </div>
          ),
        });
      }

      setItems(items);
    }
  }, [ticketData]);

  useEffect(() => {
    if (newTicket.data && !newTicket.isLoading) {
      message.success("Reply sent successfully.");
      navigate(-1);
    } else if (newTicket.error && !newTicket.isLoading) {
      message.success("Please try again.");
    }
  }, [newTicket]);

  const onReply = async () => {
    form.validateFields().then((values) => {
      const formData = new FormData();
      for (const name in values) {
        if (name === "images") {
          if (values["images"] != undefined) {
            //appending multi images to form data
            for (let i = 0; i < values["images"].fileList.length; i++) {
              formData.append(
                "attachments",
                values["images"].fileList[i].originFileObj,
                values["images"].fileList[i].name
              );
            }
          }
        } else {
          formData.append(name, values[name]);
        }
      }
      //formData.append("reply", editorRef.current.getContent());
      putNewTicketReply(formData);
    });
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  return (
    <Space direction="vertical">
      <Card title={ticketDetails.subject}>
        <Descriptions>
          <Descriptions.Item label="Ticket Number">
            {ticketDetails.id}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <Tag color="green">{ticketDetails.status}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Create Date">
            {ticketDetails.raisedOn}
          </Descriptions.Item>
          <Descriptions.Item label="Beneficiary Name">
            {ticketDetails.beneficiaryDisplayName}
          </Descriptions.Item>
        </Descriptions>
        <Collapse items={items} />
      </Card>
      {!location.state.isViewOnly && (
        <Card>
          <Form layout="vertical" form={form} onFinish={onReply}>
            <Row gutter={16}>
              <Col span={24}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  {/* <RichTextEditor title={""} height={150} visible={true} editorRef={editorRef} /> */}
                  <Form.Item name="details" label="Reply">
                    <TextArea> </TextArea>
                  </Form.Item>

                  <Form.Item name="images" label="Upload Images">
                    <Upload.Dragger
                      {...uploadProps}
                      customRequest={dummyRequest}
                      listType="picture-card"
                      fileList={fileList}
                      onChange={handleChange}
                      multiple={true}
                    >
                      {
                        <>
                          <p className="ant-upload-drag-icon">
                            <UploadOutlined />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">
                            Support for a single or bulk upload.
                          </p>
                        </>
                      }
                    </Upload.Dragger>
                  </Form.Item>
                </Space>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Send Reply
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      )}
    </Space>
  );
}

export default TicketDetails;
