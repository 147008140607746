import { Button, Card, message, Popconfirm, Space, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../../constants";
import useApi from "../../../hooks/useApi";
import TableComponent from "../../common/table/TableComponent";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { setDataToLocalStorage } from "../../../generalFunctions";
import AddWard from "./AddWard";

function WardTable() {
  const [activeTab, setActiveTab] = useState("wards");
  const columns = [
    {
      title: "Ward Name",
      dataIndex: "name",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          {activeTab === "wards" && (
            <Space>
              <Button size="small" onClick={() => editRecord(record)}>
                <EditOutlined />
              </Button>
              <Popconfirm
                title="Delete Ward"
                description={`Are you sure to delete ${record.name}?`}
                onConfirm={() => deleteOk(record)}
              >
                <Button size="small">
                  <DeleteOutlined style={{ color: "red" }} />
                </Button>
              </Popconfirm>
            </Space>
          )}
          {activeTab === "deactivated" && (
            <Space>
              <Popconfirm
                title="Reactivate Ward"
                description={`Are you sure to reactivate ${record.name}?`}
                onConfirm={() => reactivateRecord(record)}
              >
                <Button size="small">reactivate</Button>
              </Popconfirm>
            </Space>
          )}
        </>
      ),
    },
  ];

  const [responseData, getRequest] = useApi(
    `/config/ward?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );
  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState();
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [deleteResponse, deleteRequest] = useApi();
  const [clientConfig, getClientConfig] = useApi(
    "/config",
    null,
    API_METHODS.GET
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [deactivatedWardData, getDeactivatedWards] = useApi(
    `/config/ward/deactivated?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );
  const [reactivateResponse, reactivateRequest] = useApi();
  const [totalRecords, setTotalRecords] = useState(0);

  const refreshClientConfig = () => {
    getClientConfig();
  };

  useEffect(() => {
    if (clientConfig.data) {
      setDataToLocalStorage("clientConfig", clientConfig.data);
    }
  }, [clientConfig]);

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    setTableData(data);
    setTotalRecords(data && data.length > 0 ? data[0].count : 0);
  }, [responseData]);

  useEffect(() => {
    const data =
      deactivatedWardData.data !== BLANK_VALUE ? deactivatedWardData.data : [];
    setTableData(data);
    setTotalRecords(data && data.length > 0 ? data[0].count : 0);
  }, [deactivatedWardData]);

  useEffect(() => {
    if (deleteResponse.data) {
      getRequest();
      refreshClientConfig();
      message.success("Ward Deleted successfully.");
    }
  }, [deleteResponse]);

  useEffect(() => {
    if (reactivateResponse.data) {
      getRequest();
      refreshClientConfig();
      setActiveTab("wards");
      message.success("Ward Reactivated successfully.");
    }
  }, [reactivateResponse]);

  const handleAddWard = (isUpdated) => {
    const flag = visible ? false : true;
    setVisible(flag);
    if (isUpdated === true) {
      getRequest();
      setActiveTab("wards");
      refreshClientConfig();
    }
  };

  const handleEditWard = (isUpdated) => {
    const flag = visibleEdit ? false : true;
    !flag && setSelectedRecord(null);
    setVisibleEdit(flag);
    if (isUpdated === true) {
      getRequest();
      refreshClientConfig();
    }
  };

  const editRecord = (record) => {
    setVisibleEdit(true);
    setSelectedRecord(record);
  };

  const onPaginationChange = (page) => {
    if (activeTab === "deactivated") {
      getDeactivatedWards(
        null,
        API_METHODS.GET,
        `/config/ward/deactivated?pageNumber=${page.current}`
      );
    } else {
      getRequest(
        null,
        API_METHODS.GET,
        `/config/ward?pageNumber=${page.current}`
      );
    }
    setCurrentPage(page.current);
  };

  const deleteOk = (record) => {
    deleteRequest(null, API_METHODS.DELETE, `/config/ward/${record.id}`);
  };

  const reactivateRecord = (record) => {
    //Promise reqd see antd popconfirm Asynchronously close on Promise example
    reactivateRequest(
      null,
      API_METHODS.POST,
      `/config/ward/reactivate/${record.id}`
    );
  };

  const items = [
    {
      key: "wards",
      label: "Active Wards",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
    {
      key: "deactivated",
      label: "Deactivated Wards",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={deactivatedWardData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
  ];

  const onTabChange = (key) => {
    setActiveTab(key);
    setCurrentPage(1);
    if (key === "wards") {
      getRequest();
    } else {
      getDeactivatedWards();
    }
  };

  return (
    <Card title="Wards">
      <Tabs
        defaultActiveKey="wards"
        activeKey={activeTab}
        items={items}
        onChange={onTabChange}
        tabBarExtraContent={
          <Button type="primary" onClick={handleAddWard}>
            Add Ward
          </Button>
        }
      />
      {visible && (
        <AddWard visible={visible} close={handleAddWard} edit={false} />
      )}
      {visibleEdit && (
        <AddWard
          visible={visibleEdit}
          selectedRecord={selectedRecord}
          close={handleEditWard}
          edit={true}
        />
      )}
    </Card>
  );
}

export default WardTable;
