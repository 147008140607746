import { Button, Card, Col, Popconfirm, Row, Tabs, Typography, message, Space, Switch } from "antd";
import React, { useEffect, useState } from "react";
import AddYoutubeLink from "./AddYoutubeLink";
import useApi from "../../hooks/useApi";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import { DeleteOutlined } from "@ant-design/icons";
import { isEmpty } from "../../generalFunctions";
import YouTube from 'react-youtube';
const { Text } = Typography;

function ViewYoutubeLinks() {
  const [visible, setVisible] = useState(false);
  const [commonVideos, setCommonVideos] = useState([]);
  const [beneficiaryVideos, setBeneficiaryVideos] = useState([]);
  const [deleteResponse, deleteRequest] = useApi();
  const [responseData, getRequest] = useApi(
    "/admin/video",
    null,
    API_METHODS.GET
  );
  const [activeTab, setActiveTab] = useState("common");
  const [togglePriorityResponse, togglePriorityRequest] = useApi(
    "/admin/video",
    null,
    API_METHODS.PUT
  );

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    getRequest();
  }, [togglePriorityResponse]);

  useEffect(() => {
    if (deleteResponse.data) {
      getRequest();
      message.success("Video deleted successfully.");
    }
  }, [deleteResponse]);

  const deleteOk = (record) => {
    deleteRequest(null, API_METHODS.DELETE, `/admin/video/${record.id}`);
  };

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  const togglePriority = (data, priority) => {
    let body = { priority };
    togglePriorityRequest(body, API_METHODS.PUT, `/admin/video/${data.id}`);
  };

  const getDeleteButton = (data) => {
    return (
      <>
        <Popconfirm
          title="Delete Video Link"
          description={`Are you sure to delete this youtube video?`}
          onConfirm={() => deleteOk(data)}
        >
          <Button size="small" type="primary" danger>
            <DeleteOutlined /> delete
          </Button>
        </Popconfirm>
      </>
    );
  };

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    if (data && data.length) {
      let commonVideos = [];
      let beneficiaryVideos = [];
      for (let i = 0; i < data.length; i++) {
        if (isEmpty(data[i].beneficiaryIds)) {
          commonVideos.push(
            <Col span={6} style={{ marginBottom: 15 }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Card title={data[i].videoTittle} size="small">
                  <Row gutter={16}>
                    <Col span={24}>
                      <YouTube
                        videoId={data[i].videoId}
                        id={data[i].videoId}
                        opts={{
                          height: "195",
                          width: "100%",
                          playerVars: {
                            rel: 0,
                          },
                        }}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Space>
                        <><strong>Priority:</strong> <Switch checkedChildren="High" unCheckedChildren="Low" onClick={(value) => togglePriority(data[i], value)} checked={data[i].priority}></Switch></>
                        {getDeleteButton(data[i])}
                      </Space>
                    </Col>
                  </Row>
                </Card>
              </Space>
            </Col>
          );
        } else {
          beneficiaryVideos.push(
            <Col span={6} style={{ marginBottom: 15 }}>
              <Space direction="vertical" size={24} style={{ width: "100%" }}>
                <Card title={data[i].videoTittle} size="small">
                  <Row gutter={16}>
                    <Col span={24}>
                      <YouTube
                        videoId={data[i].videoId}
                        id={data[i].videoId}
                        opts={{
                          height: "195",
                          width: "100%",
                          playerVars: {
                            rel: 0,
                          },
                        }}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={18}>
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <Text>Benificiaries: {<strong>{data[i].beneficiaries.toString()}</strong>}</Text>
                      </Space>
                    </Col>
                    <Col span={3}>
                      {getDeleteButton(data[i])}
                    </Col>
                  </Row>
                </Card>
              </Space>
            </Col>
          );
        }
      }
      setCommonVideos(commonVideos);
      setBeneficiaryVideos(beneficiaryVideos);
    }
  }, [responseData]);

  const handleAddLinks = (isUpdated) => {
    const flag = visible ? false : true;
    setVisible(flag);
    if (isUpdated === true) {
      getRequest();
    }
  };

  const items = [
    {
      key: "common",
      label: "Common Videos",
      children: <Row gutter={16}>{commonVideos}</Row>,
    },
    {
      key: "beneficiary",
      label: "For Particular Beneficiaries",
      children: <Row gutter={16}>{beneficiaryVideos}</Row>,
    },
  ];

  return (
    <Card title="Training Center Videos">
      {visible && (
        <AddYoutubeLink
          visible={visible}
          close={handleAddLinks}
          edit={false}
          tab = {activeTab}
        />
      )}
      <Tabs
        defaultActiveKey="common"
        activeKey={activeTab}
        items={items}
        onChange={onTabChange}
        tabBarExtraContent={
          <Button type="primary" onClick={handleAddLinks}>
            Add Video Links
          </Button>
        }
      />
    </Card>
  );
}

export default ViewYoutubeLinks;
